<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuova nota spesa</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="creaSpesa()">
                    <ion-list class="fields">
                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="spesa.data"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- prezzo -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Importo
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-input type="number" min="0" step="any" v-model="spesa.importo" placeholder="Importo della spesa"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Descrizione</div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="3"
                                        v-model="spesa.descrizione"
                                        placeholder="Inserisci la descrizione della spesa effettuata"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <ion-item v-if="userPhoto.length != 0" lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div v-for="photo in userPhoto" :key="photo">
                                    <ion-img :src="photo.webviewPath" class="img_photo"></ion-img>
                                    <div @click="showActionSheet(photo)" class="img_action">
                                        <ion-icon :icon="trash"></ion-icon>
                                    </div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions">
                            <button class="btn_foto" type="button" @click="showActionSheetPhotos()">
                                Aggiungi foto
                            </button>
                            <button type="submit" class="btn_richiesta">
                                Inserisci nota spesa
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonInput,
    IonIcon,
    IonImg,
    actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline, camera, images, close, trash } from "ionicons/icons";

import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";
import imageToBase64 from "image-to-base64/browser";

import { openToast } from "../services/toast";

import apiSpese from "../services/spese";

import { usePhotoGallery, UserPhoto } from "../services/usePhotoGallery";

export default defineComponent({
    name: "ModalNuovaNotaSpesa",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonInput,
        IonIcon,
        IonImg,
    },
    setup(props, context) {
        const router = useRouter();

        const start_date = new Date();
        const end_hour = new Date();
        const start_hour = new Date(end_hour);
        start_hour.setHours(start_hour.getHours() + 1);
        end_hour.setHours(start_hour.getHours() + 2);

        const userID = JSON.parse(localStorage.getItem("unserInfoEntasys")).users_id; //set userID in user field
        const dipendenteID = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_id;

        const spesa = reactive({
            data: start_date.toISOString(),
            importo: "0",
            descrizione: "",
        });

        const successResponse = ref(false);

        const { userPhoto, takePhoto, deletePhoto, pickPhotos } = usePhotoGallery();

        let photoToSave = null;

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle camera and device selection photos
         */
        const showActionSheetPhotos = async () => {
            const actionSheet = await actionSheetController.create({
                header: "Scegli modalità di caricamento",
                buttons: [
                    {
                        text: "Fotocamera",
                        icon: camera,
                        handler: () => {
                            deletePhoto(); //cancella eventuale foto caricata precedentemente
                            takePhoto();
                        },
                    },
                    {
                        text: "Galleria",
                        icon: images,
                        handler: () => {
                            deletePhoto(); //cancella eventuale foto caricata precedentemente
                            pickPhotos();
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /**
         * ! Handle delete single photo
         */
        const showActionSheet = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            //deletePhoto(photo);
                            deletePhoto();
                            photoToSave = null;
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /**
         * !  Iterates all photos, convert to base64 and return.
         */
        const conversioneFoto = async (userPhoto) => {
            const requests = userPhoto.map((photo) => {
                return imageToBase64(photo.webviewPath) // Path to the image
                    .then((response) => {
                        //console.log(response);
                        const fileName = photo.filepath;
                        //fileName = fileName.substring(0, fileName.length - 5);
                        //console.log(str);
                        const foto = {
                            name: fileName,
                            data: response,
                        };
                        return foto;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
            return Promise.all(requests); // Waiting for all the requests to get resolved.
        };

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Create new spesa
         */
        async function creaSpesa() {
            const dataSpesa = moment(spesa.data_inizio).format("YYYY-MM-DD");

            //Se non ho data mostro errore
            if (!spesa.data) {
                openToast("La data della spesa è obbligatoria", "toast_danger");
                return;
            }
            //Importo maggiore di zero
            if (!spesa.importo || spesa.importo == 0) {
                openToast("L'importo della spesa deve essere maggiore di zero", "toast_danger");
                return;
            }

            const data = new FormData();
            data.append("nota_spese_dipendente", dipendenteID); //logged user
            data.append("nota_spese_data", dataSpesa);
            data.append("nota_spese_descrizione", spesa.descrizione);
            data.append("nota_spese_importo", spesa.importo);
            data.append("nota_spese_rimborsata", 0);
            /**
             * ! Se ho caricato foto la converto, passo il dato a FormData e continuo con il salvataggio
             */

            if (userPhoto.value.length) {
                const convertedPhoto = await conversioneFoto(userPhoto.value);
                photoToSave = convertedPhoto[0].data;
                data.append("nota_spese_foto_ricevuta", photoToSave);
            }
            /* Display the values
      for (const value of data.entries()) {
        console.log(value[0] + ", " + value[1]);
      }
      return; */

            apiSpese
                .saveSpesa(data)
                .then((response) => {
                    if (response.data.status == 8) {
                        openToast(response.data.message, "toast_danger");
                    } else {
                        const res = response.data.data[0];
                        successResponse.value = true;
                        closeModalOnSubmit(successResponse, res);
                    }
                })
                .catch((error) => {
                    //console.error(error);
                    openToast("Errore durante la creazione della nota spesa", "toast_danger");
                })
                .finally(() => {
                    console.log("fine creazione nota spesa");
                });
        }

        return {
            spesa,
            creaSpesa,
            closeModal,
            arrowBackOutline,
            showActionSheetPhotos,
            showActionSheet,
            userPhoto,
            trash,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --highlight-height: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}
ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 0;
}

/* .btn_richiesta {
  --background: #16a34a;
  --background-activated: #15803d;
  --background-hover: #15803d;
  --background-focused: #15803d;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
} */
.btn_richiesta {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    text-align: center;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}

/* .btn_foto {
  --background: #0284c7;
  --background-activated: #0369a1;
  --background-hover: #0369a1;
  --background-focused: #0369a1;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 16px;
} */
.btn_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    background-color: #0a90d4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
}

/* Image rules */
.img_photo {
    position: relative;
    max-height: 300px;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #dc2626;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
ion-button {
    --color: #ffffff;
}
</style>
